<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col cols="21" xl="6" class="d-flex justify-content-between flex-column">
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <button class="btn btn-primary mb-3 mr-3" @click="showAddCostModal">إضافة عهده</button>
              <add-cost ref="addCost" @add-cost="updateCost" />
              <button class="btn btn-primary mb-3 mr-3" @click="$bvModal.show('modal-danger')">سحب العهده</button>

              <button class="btn btn-primary mb-2 mr-1" @click="toggle((payment = 'expenses'))">المصاريف</button>
              <button class="btn btn-primary mb-2 mr-2" @click="toggle((payment = 'coupons'))">القسايم</button>
              <button class="btn btn-primary mb-2 mr-3" @click="toggle((payment = 'custody'))">العهده</button>
              <b-modal id="modal-danger" centered header="test" header-class="justify-content-center" title="تأكيد السحب" hide-footer>
                <div class="col-12 text-center">
                  <p>
                    <strong>هل انت متأكد من سحب العهده من {{ agent.userDisplayName }}</strong
                    >؟
                  </p>

                  <b-button variant="danger" size="sm" class="mt-2 mr-2" @click="ResetCost(agent.id) + $bvModal.hide('modal-danger')">تأكيد</b-button>
                  <b-button variant="primary" size="sm" class="mt-2 ml-2" @click="$bvModal.hide('modal-danger')">إلغاء</b-button>
                </div>
              </b-modal>
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <div class="d-flex align-items-center mt-2"></div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="12" xl="6">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon icon="UserIcon" class="mr-75" />
              <span class="font-weight-bold">الاسم</span>
            </th>
            <td class="pb-50">
              {{ this.agent.userDisplayName }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="CheckIcon" class="mr-75" />
              <span class="font-weight-bold">اسم المستخدم</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ this.agent.userName }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="StarIcon" class="mr-75" />
              <span class="font-weight-bold">أماكن الخدمه</span>
            </th>
            <td class="pb-50 text-capitalize">
              <span class="btn btn-success" v-for="place in agent.servicePlaces" :key="place" :style="'margin-right:1vh'">{{ place }}</span>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="DollarSignIcon" class="mr-75" />
              <span class="font-weight-bold">العهده</span>
            </th>
            <td class="pb-50">
              {{ agent.custody }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BButton, BAvatar, BRow, BCol } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AddCost from './add-cost.vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    AddCost
  },
  props: ['id', 'agent'],
  data() {
    return {
      // agent: {},
      payment: 'expenses'
    }
  },
  // async mounted() {
  //   await this.GetAgent(this.$route.params.id)
  // },

  methods: {
    showAddCostModal() {
      this.$refs.addCost.showModal()
    },
    // async GetAgent(agentId) {
    //   try {
    //     const response = await this.$store.dispatch('GetAgent', agentId)
    //     if ((response.status = 200)) {
    //       this.agent = response.data
    //     }
    //   } catch (err) {
    //     this.$toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
    //         icon: 'TrashIcon',
    //         variant: 'danger'
    //       }
    //     })
    //   }
    // },
    AddCost(id) {
      this.$router.push({
        name: 'add-cost',
        params: { id: id }
      })
    },
    updateCost(value) {
      this.agent.custody = this.agent.custody + value
    },

    ResetCost(id) {
      this.$emit('withdraw')
      this.agent.custody = 0
    },
    toggle(payment) {
      this.$emit('toggle', payment)
    }
  }
}
</script>

<style></style>
