<template>
  <b-card-code title="" style="margin-top: 0px">
    <agent-card :id="id" :key="componentKey" @withdraw="WithDraw" @toggle="ToggleTables" :agent="agent" />
    <div v-if="toggleTables == 'expenses'">
      <payments :key="componentKey" :id="id" @updatePrice="updatePrice" />
    </div>
    <div v-else-if="toggleTables == 'coupons'">
      <coupons :key="componentKey" :id="id" @updatePrice="updatePrice" />
    </div>
    <div v-else>
      <custody :key="componentKey" :id="id" />
    </div>
    <template #code>
      {{ codeBasic }}
    </template>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BButton,
  BAvatar,
  BBadge,
  BModal,
  VBModal,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormDatepicker
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import { codeBasic } from '../code'
import agentCard from './agent-card.vue'

import payments from './expenses-payments.vue'
import coupons from './coupons-payment.vue'
import custody from './custody.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    'agent-card': agentCard,
    coupons: coupons,
    payments: payments,
    custody: custody,
    BCardCode,
    VueGoodTable,
    BButton,
    BModal,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormDatepicker
  },

  directives: {
    'b-modal': VBModal,
    Ripple
  },
  props: ['id'],
  async mounted() {
    await this.GetAgent(this.$route.params.id)
  },
  data() {
    return {
      pageLength: 7,
      dir: false,
      codeBasic,
      toggleTables: 'expenses',
      agent: {},
      componentKey: 0
    }
  },
  methods: {
    async GetAgent(agentId) {
      try {
        const response = await this.$store.dispatch('GetAgent', agentId)
        if ((response.status = 200)) {
          this.agent = response.data
          this.componentKey += 1
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
            icon: 'TrashIcon',
            variant: 'danger'
          }
        })
      }
    },

    updatePrice(value) {
      console.log(value)
      this.agent.custody = this.agent.custody - value
    },

    async WithDraw() {
      try {
        const response = await this.$store.dispatch('WithDrawCost', this.$route.params.id)
        if ((response.status = 204)) {
          this.agent.custody = 0
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'تم سحب العهده',
              icon: 'CheckIcon',
              variant: 'success'
            }
          })
        }
      } catch (err) {
        if (err.response) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.response.data.errorMessages[0] ? err.response.data.errorMessages[0] : '',
              icon: 'XIcon',
              variant: 'warning'
            }
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
              icon: 'XIcon',
              variant: 'danger'
            }
          })
        }
      }
    },
    ToggleTables(value) {
      this.toggleTables = value
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    }
  }
}
</script>
