<template>
  <div>
    <div class="d-flex flex-row justify-content-between mb-1">
      <div class="d-flex w-50">
        <b-form-datepicker
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          locale="ar"
          class="mr-2"
          size="lg"
          v-model="from"
          placeholder="من"
        ></b-form-datepicker>
        <b-form-datepicker
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          locale="ar"
          class="mr-2"
          size="lg"
          v-model="to"
          placeholder="إلى"
        ></b-form-datepicker>
        <b-button variant="primary" @click="FilterExpenses()">
          <feather-icon icon="SearchIcon"></feather-icon>
        </b-button>
      </div>
      <div>
        <b-button variant="primary" @click="DownloadData()">
          <feather-icon icon="FileTextIcon" size="16" />
          طباعة تقرير كامل
        </b-button>
      </div>
    </div>

    <vue-good-table
      :key="tableRenderKey"
      :columns="columns"
      :rows="this.payments"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength
      }"
    >
      <template slot="table-column" slot-scope="props">
        <span v-if="props.column.label == 'التفاصيل'">
          <span>التفاصيل</span>
        </span>
      </template>

      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'price'" class="text-nowrap">
          <span class="text-nowrap">{{ props.row.price }}</span>
        </span>
        <span v-else-if="props.column.field === 'isReviewed'" class="text-nowrap">
          <span v-if="!props.row.isReviewed" class="text-nowrap">
            <b-button variant="warning" :disabled="isReviewing[props.row.id]" @click="reviewExpense(props.row)">
              <feather-icon v-if="!isReviewing[props.row.id]" icon="XIcon" size="16" />
              <b-spinner v-else small></b-spinner>
            </b-button>
          </span>
          <span v-else class="text-nowrap">
            <b-button variant="success"> <feather-icon icon="CheckIcon" size="16" /></b-button
          ></span>
        </span>
        <span v-else-if="props.column.field === 'action'">
          <button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            style="margin-right: 16px"
            class="btn btn-danger"
            @click="$bvModal.show(props.row.id.toString())"
          >
            <feather-icon icon="Trash2Icon" size="12" />
          </button>
          <b-modal :id="props.row.id.toString()" centered header="test" header-class="justify-content-center" title="تأكيد الحذف" hide-footer>
            <div class="col-12 text-center">
              <p>
                <strong>هل انت متأكد من الحذف</strong>
              </p>
              <b-button variant="primary" size="sm" class="mt-2 mr-2" @click="DeletePayment(props.row.id) + $bvModal.hide(props.row.id.toString())"
                >تأكيد</b-button
              >
              <b-button variant="danger" size="sm" class="mt-2 ml-2" @click="$bvModal.hide(props.row.id.toString())">إلغاء</b-button>
            </div>
          </b-modal>
        </span>
        <span v-else-if="props.column.field === 'date'">
          <span class="text-nowrap">{{ formatDate(props.row.date) }}</span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> عرض الصفحة الأولى </span>

            <b-form-select v-model="pageLength" :options="['3', '7', '10', '15', '20', '50']" class="mx-1" @change="onPerPageChange" />
            <span class="text-nowrap"> من {{ totalCount }} العناصر </span>
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              class="mt-1 mb-0"
              @input="handlePageChange"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import * as XLSX from 'xlsx'
import {
  BButton,
  BAvatar,
  BBadge,
  BModal,
  VBModal,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormDatepicker,
  BSpinner
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import { format } from 'date-fns'
import Ripple from 'vue-ripple-directive'
import { codeBasic } from '../code'
export default {
  components: {
    BCardCode,
    VueGoodTable,
    BButton,
    BModal,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormDatepicker,
    BSpinner
  },

  directives: {
    'b-modal': VBModal,
    Ripple
  },
  props: ['id'],

  created() {
    this.FilterExpenses()
  },
  data() {
    return {
      isReviewing: {},
      payments: [],
      pageLength: 7,
      currentPage: 1,
      totalCount: 0, // Total rows/items count from API
      totalPages: 0, // Total pages count from API
      dir: false,
      codeBasic,
      columns: [
        {
          label: 'السعر',
          field: 'price',
          width: '10%'
        },
        {
          label: 'البيان',
          field: 'details'
        },
        {
          label: 'التاريخ',
          field: 'date',
          width: '20%'
        },
        {
          label: 'حالة المراجعة',
          field: 'isReviewed',
          width: '8%',
          sortable: false,
          tdClass: 'text-center',
          thClass: 'text-center'
        },
        {
          label: 'التفاصيل',
          field: 'action',
          width: '8%',
          sortable: false,
          tdClass: 'text-center',
          thClass: 'text-center'
        }
      ],
      from: '',
      to: '',
      searchTerm: '',
      agent: null,
      tableRenderKey: 0
    }
  },
  methods: {
    formatDate(date) {
      return format(new Date(date), 'dd/MM/yyyy')
    },

    async onPerPageChange(newPerPage) {
      // Update pageLength and refresh data for the current page
      this.pageLength = parseInt(newPerPage, 10)
      await this.FilterExpenses()
    },
    async handlePageChange() {
      // Fetch data for the current page
      await this.FilterExpenses()
    },
    async FilterExpenses(all = false) {
      if (this.from === '' || this.to === '') {
        let today = new Date()
        today.setHours(0, 0, 0, 0) // Set time to 12:00 AM
        this.from = today
        let nextDay = new Date()
        nextDay.setDate(nextDay.getDate() + 1) // Add 1 day
        nextDay.setHours(0, 0, 0, 0) // Set time to 12:00 AM
        this.to = nextDay
      }

      const payload = {
        agentId: this.id,
        startDate: this.from,
        endDate: this.to,
        page: this.currentPage,
        pageSize: all == true ? this.totalCount : this.pageLength
      }
      try {
        const response = await this.$store.dispatch('FilterExpenses', payload)
        if (response.data.isSuccess) {
          if (all == true) {
            return response.data.result
          }
          this.payments = response.data.result
          this.tableRenderKey++
          this.totalCount = response.data.totalCountData
          this.totalPages = response.data.pagesCount
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
            icon: 'TrashIcon',
            variant: 'danger'
          }
        })
      }
    },
    async DownloadData() {
      let jsonData = []
      await this.FilterExpenses(true).then((data) => {
        data.forEach((payment) => {
          jsonData.push({
            السعر: payment.price,
            البيان: payment.details,
            التاريخ: this.formatDate(payment.date),
            'حالة المراجعة': payment.isReviewed
          })
        })
      })
      if (jsonData.length <= 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'لا توجد بيانات للتصدير',
            icon: 'TrashIcon',
            variant: 'danger'
          }
        })
        return
      }

      await this.GetAgent()
      let agentName = this.agent.userDisplayName

      const data = XLSX.utils.json_to_sheet(jsonData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, data, 'data')
      XLSX.writeFile(wb, 'تقرير-مصاريف-مندوب-' + agentName + '.xlsx')
    },
    async GetAgent() {
      try {
        const response = await this.$store.dispatch('GetAgent', this.id)
        if ((response.status = 200)) {
          this.agent = response.data
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
            icon: 'TrashIcon',
            variant: 'danger'
          }
        })
      }
    },

    async DeletePayment(id) {
      try {
        const response = await this.$store.dispatch('DeletePayment', id)
        if ((response.status = 200)) {
          this.payments = this.payments.filter((i) => i.id !== id)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'تم الحذف بنجاح',
              icon: 'CheckIcon',
              variant: 'success'
            }
          })
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
            icon: 'TrashIcon',
            variant: 'danger'
          }
        })
      }
    },
    async reviewExpense(expense) {
      this.$set(this.isReviewing, expense.id, true)
      try {
        const response = await this.$store.dispatch('ReviewExpense', expense.id)
        if (response.data.isSuccess) {
          this.$set(this.isReviewing, expense.id, false)

          const index = this.payments.findIndex((i) => i.id === expense.id)
          this.payments[index].isReviewed = true

          this.$emit('updatePrice', expense.price)

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'تم الاعتماد',
              icon: 'CheckIcon',
              variant: 'success'
            }
          })
        }
      } catch (err) {
        this.$set(this.isReviewing, expense.id, false)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'حدث خطأ أثناء المراجعة',
            icon: 'XCircleIcon',
            variant: 'danger'
          }
        })
      }
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    }
  }
}
</script>

<style lang="scss" scoped>
.date {
  max-width: 400px;
  padding: 1;
  margin-right: 9vh;
  margin-bottom: 4vh;
}
.vgt-left-align {
  max-width: 100px;
}
::v-deep .vs__dropdown-toggle {
  padding: 0.5rem;
}
::v-deep .dropdown-menu {
  width: 270px;
  z-index: 9999;
}
::v-deep [dir] .vs__dropdown-toggle {
  padding: 0 0 4px;
  background: none;
  border-radius: 4px;
  padding: 0.4rem;
}
</style>
